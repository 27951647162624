import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  RecordsListContainer,
  PreviewImage,
  StyledTableCellHead,
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableContainer,
  StyledTableHead,
  StyledTableRow,
  StyledTableCellImage,
  DeleteIcon,
  NextButton,
  ParentContainer,
  StyleTableName,
  ValidationError,
  EmptyListMessage,
} from "./style.components";
import { Select, MenuItem, Box, Checkbox } from "@mui/material";
import IDeleteIcon from "../../../assets/delete-small-icon.svg";
import StatusDropdown from "../../common/StatusDropdown/StatusDropdown";
import { sendRecordsAction, getConfigsAction } from "../../../store/actions";
import { getRecordsAction } from "../../../store/actions/records.action";
import SimpleLoader from "../../common/Loaders/SimpleLoader";
import SnackBar from "../../common/SnackBar/SnackBar";
import Breadcrumb from "../../common/Breadcrumb/Breadcrumb";
import studyIcon from "../../../assets/study-icon.svg";

const dropdownOptions = [
  { label: "Available", value: "AVAILABLE" },
  { label: "Restricted", value: "RESTRICTED" },
];

const SendRecords = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedPatientId = useSelector((state) => state.patient.selectedPatientId);
  const [records, setRecords] = useState(null);
  const [recordList, setRecordList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(dropdownOptions[0].value);
  const [uppyResponse, setUppyResponse] = useState(null);
  const [recordType, setRecordType] = useState(null);
  const [checked, setChecked] = useState(false);
  const [isDirectLinkEnabled, setDirectLinkEnabled] = useState(false);
  const [scansList, setScansList] = useState([]);

  const breadCrumbLinks = [
    {
      label: "Health Data",
      href: `/patients/patientId?name=${selectedPatientId}`,
    },
    {
      label: "Add Records",
      href: `/patient-records/add-records`,
    },
    {
      label: "Send Records",
    },
  ];

  useEffect(() => {
    getAllConfigs();
  }, [dispatch]);

  const getAllConfigs = async () => {
    try {
      const response = await dispatch(getConfigsAction()).unwrap();
      if (response?.status === "success") {
        const configs = response?.data;
        const directLinkConfig = configs.find(
          (config) => config?.config_name === "direct_link"
        );
        if (directLinkConfig) {
          setDirectLinkEnabled(directLinkConfig.config_value === "true");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const { records, uppyResponse, recordType } = location?.state || {};
    setRecords(records);
    setUppyResponse(uppyResponse);
    setRecordType(recordType);
    setScansList(uppyResponse?.uploadedFiles || []);
  }, [location]);

  useEffect(() => {
    if (records && uppyResponse?.uploadedFiles) {
      const mergedRecordList = records?.map((record) => {
        const matchedFile = uppyResponse.uploadedFiles.find(
          (file) => file.file_name === record.original_file_name
        );
        if (matchedFile) {
          return {
            ...record,
            file_id: matchedFile.file_id,
            file_name: matchedFile.file_name,
            mimetype: matchedFile.mimetype,
            file_size: matchedFile.file_size,
          };
        }
        return record;
      });

      setRecordList(mergedRecordList);
      setSelectedStatuses(mergedRecordList.map(() => dropdownOptions[0].value));
    }
  }, [records, uppyResponse]);

  const handleStatusChangeForDocs = (index) => (event) => {
    const newStatuses = [...selectedStatuses];
    newStatuses[index] = event.target.value;
    setSelectedStatuses(newStatuses);
  };

  const handleStatusChangeForScans = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleRemoveRecord = (index) => {
    setRecordList((prevList) => prevList.filter((_, i) => i !== index));
  };

  const handleRemoveScans = (index) => {
    setScansList((prevList) => prevList.filter((_, i) => i !== index));
  };

  const handleDirectLinkChange = (event) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
  };

  const handleAddRecords = async () => {
    setIsLoading(true);
    try {
      if (recordType === "docs") {
        const payload = {
          patient_id: selectedPatientId,
          report_info: recordList.map((record, index) => ({
            file_id: record?.file_id,
            mimetype: "application/pdf",
            file_size: record?.file_size,
            file_name: record?.converted_file_name || record?.file_name,
            file_type: "report",
            uploaded_file_url: record?.uploaded_file_url || record?.file_gcp_location,
            status: selectedStatuses[index],
          })),
        };
        const response = await dispatch(sendRecordsAction({ type: "report", payload })).unwrap();
        if (response?.status === "success") {
          await dispatch(
            getRecordsAction({
              patient_id: selectedPatientId,
              report_type: "report",
              limit: 5,
            })
          ).unwrap();
          navigate(`/patients/patientId?name=${selectedPatientId}`);
        }
      } else if (recordType === "scans") {
        const payload_scans = {
          file_info: {
            folder_id: uppyResponse?.assemblyID,
            status: selectedStatus,
            direct_viewer_link: checked,
          },
          patient_id: selectedPatientId,
        };
        const response = await dispatch(sendRecordsAction({ type: "scans", payload: payload_scans })).unwrap();
        if (response?.status === "success") {
          await dispatch(
            getRecordsAction({
              patient_id: selectedPatientId,
              report_type: "scans",
              limit: 5,
            })
          ).unwrap();
          navigate(`/patients/patientId?name=${selectedPatientId}`);
        }
      }
    } catch (error) {
      console.error("Error adding records:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ParentContainer>
      <Breadcrumb links={breadCrumbLinks} />
      <RecordsListContainer>
        <StyledTableContainer>
          <StyledTable>
            <StyledTableHead>
              <StyledTableRow>
                <StyledTableCellHead>Preview</StyledTableCellHead>
                <StyledTableCellHead>File Name</StyledTableCellHead>
                <StyledTableCellHead>Category</StyledTableCellHead>
                {recordType === "docs" && <StyledTableCellHead>Status</StyledTableCellHead>}
                <StyledTableCellHead>Remove</StyledTableCellHead>
              </StyledTableRow>
            </StyledTableHead>
            <StyledTableBody>
              {recordType === "docs" &&
                recordList.map((record, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCellImage>
                      <PreviewImage src={record?.thumbnail_gcp_location} alt={record?.converted_file_name || record?.file_name} />
                    </StyledTableCellImage>
                    <StyledTableCell>
                      <StyleTableName>{record?.converted_file_name || record?.file_name}</StyleTableName>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyleTableName>Report</StyleTableName>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StatusDropdown
                        selectedStatus={selectedStatuses[index]}
                        handleChange={handleStatusChangeForDocs(index)}
                        dropdownOptions={dropdownOptions}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <DeleteIcon src={IDeleteIcon} onClick={() => handleRemoveRecord(index)} />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}

              {recordType === "scans" &&
                scansList.map((record, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCellImage>
                      <PreviewImage src={studyIcon} alt={record?.file_name} />
                    </StyledTableCellImage>
                    <StyledTableCell>
                      <StyleTableName>{record?.file_name}</StyleTableName>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyleTableName>Scan</StyleTableName>
                    </StyledTableCell>
                    <StyledTableCell>
                      <DeleteIcon src={IDeleteIcon} onClick={() => handleRemoveScans(index)} />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </StyledTableBody>
          </StyledTable>
        </StyledTableContainer>

        {/* Display EmptyListMessage outside the table */}
        {(recordType === "docs" && recordList.length === 0) || (recordType === "scans" && scansList.length === 0) ? (
          <EmptyListMessage>No records</EmptyListMessage>
        ) : null}

        {scansList.length > 0 && (
          <Box display="flex" alignItems="center" mt={2} mb={2} justifyContent="flex-start">
            {recordType === "scans" && (
              <StatusDropdown
                selectedStatus={selectedStatus}
                handleChange={handleStatusChangeForScans}
                dropdownOptions={dropdownOptions}
              />
            )}
            {isDirectLinkEnabled && recordType === "scans" && (
              <>
                <Checkbox sx={{ ml: 2 }} checked={checked} onChange={handleDirectLinkChange} />
                <Box>Allow Direct Link</Box>
              </>
            )}
          </Box>
        )}

        <div style={{ display: "flex", justifyContent: "flex-end", gap: "20px" }}>
          <NextButton
            disabled={recordType === "scans" ? scansList.length < 1 : recordList.length < 1}
            onClick={handleAddRecords}
          >
            Add Records
          </NextButton>
        </div>
      </RecordsListContainer>

      {isLoading && <SimpleLoader size={24} text={"Adding record..."} />}
      <SnackBar />
    </ParentContainer>
  );
};

export default SendRecords;
