import styled from "styled-components";
import {
  HorizontalLineSep,
  HorizontalSep,
  VerticalLineSep,
  VerticalSep,
} from "../components/styled/Gaps";
import {
  COLOR_PRIMARY,
  COLOR_PRIMARY_LIGHT,
  COLOR_SECONDARY,
  COLOR_VARIANT,
  DEVICE,
} from "../utilities/constants";

import phoneIcon from "../assets/phone.svg";
import emailIcon from "../assets/email.svg";
import UserDropdown from "../components/common/UserDropdown/UserDropdown";
import { NavigateFunction, useSearchParams } from "react-router-dom";
import CustomTable from "../components/common/CustomTable/CustomTable";
import { Button } from "@mui/material";
import { numberWithCommas } from "../components/data/BasicCounts/BasicCounts";

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-top: 30px;
  @media ${DEVICE.laptop} {
    flex-direction: column;
  }
`;

const DetailsRow = styled.div`
  background-color: ${COLOR_SECONDARY};
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-top: 30px;
  padding: 20px 30px;
  @media ${DEVICE.laptop} {
    flex-direction: column;
    padding: 10px;
  }
`;

const TestsRow = styled.div`
  background-color: ${COLOR_SECONDARY};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 30px;
  padding: 20px 30px;
  @media ${DEVICE.laptop} {
    flex-direction: column;
  }
`;

const ProfileContainer = styled.div`
  background-color: ${COLOR_SECONDARY};
  border-radius: 12px;
  padding: 30px 30px;
  flex-basis: 70%;
  @media ${DEVICE.laptop} {
    margin-bottom: 20px;
  }
  @media ${DEVICE.mobileL} {
    padding: 10px;
  }
`;

const ProfileHeader = styled.div`
  color: ${COLOR_PRIMARY};
  font-size: 28px;
  text-align: left;
  @media ${DEVICE.mobileL} {
    font-size: 20px;
  }
`;

const ProfileNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 30px 0px;
`;

const ProfileName = styled.div`
  color: ${COLOR_PRIMARY};
  font-size: 20px;
  margin-left: 10px;
  text-align: left;
  @media ${DEVICE.mobileL} {
    font-size: 16px;
  }
`;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContactRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 2px 0px;
  font-size: 14px;
  img {
    margin-right: 10px;
  }
`;

const ContactText = styled.div`
  color: #808080;
`;
const ContactTextHighlighted = styled.div`
  color: #000000;
  text-align: left;
`;

const SupportContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  overflow: hidden;
  flex-basis: 40%;
`;

const SupportHeader = styled.div`
  color: ${COLOR_SECONDARY};
  background-color: ${COLOR_PRIMARY};
  font-size: 20px;
  text-align: left;
  padding: 10px 20px;
`;
const SupportBody = styled.div`
  background-color: #d7eff7;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 20px;
  flex-grow: 1;
`;
const SupportRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 2px 0px;
  font-size: 14px;
  img {
    margin-right: 10px;
  }
`;

const SupportText = styled.div`
  color: #808080;
`;
const SupportTextHighlighted = styled.div`
  color: #0099cc;
`;

const IncentivesSection = styled.div`
  color: #0099cc;
  flex-basis: 50%;
  padding: 0px 20px;
  @media ${DEVICE.laptop} {
    margin-bottom: 20px;
  }
  @media ${DEVICE.mobileL} {
    padding: 10px;
  }
`;

const PatientsSection = styled.div`
  color: #0099cc;
  flex-basis: 50%;
  padding: 0px 20px;
  @media ${DEVICE.laptop} {
    margin-bottom: 20px;
  }
  @media ${DEVICE.mobileL} {
    padding: 10px;
  }
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  color: #0099cc;
  font-size: 20px;
  @media ${DEVICE.mobileL} {
    font-size: 16px;
  }
`;

const HeaderCount = styled.div`
  color: ${COLOR_PRIMARY};
  background-color: ${COLOR_PRIMARY_LIGHT};
  font-size: 16px;
  padding: 10px;
`;

const TableGrid = styled.div`
  display: flex;
  flex-direction: column;
  color: #808080;
`;

const GridRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #808080;
`;

const GridCell = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #d7eff7;
  padding: 20px 10px;
  font-size: 14px;
  color: #808080;
  flex-basis: 40%;
  @media ${DEVICE.mobileL} {
    font-size: 12px;
    padding: 15px 5px;
  }
`;
const UserListContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
`;

const UserListText = styled.div`
  font-size: 14px;
  color: #808080;
  margin-left: 5px;
  margin-right: 15px;
`;

const tableColumns = [
  {
    key: "thumbnail",
    type: "icon",
    align: "left",
  },
  {
    type: "text",
    key: "testName",
    align: "left",
  },
  {
    type: "text",
    key: "testPrice",
    align: "left",
  },
  {
    type: "actionButton",
    subType: "edit",
    align: "right",
    handler: () => {},
  },
  {
    type: "actionButton",
    align: "right",
    subType: "delete",
    handler: () => {},
  },
];

const tableData = [
  {
    key: 1,
    testName: "CPK (Creatine Phosphokinase) Test",
    testPrice: formatCurrency(500, "INR", "en-IN"),
  },
  {
    key: 2,
    testName: "CPK (Creatine Phosphokinase) Test",
    testPrice: formatCurrency(1000, "INR", "en-IN"),
  },
  {
    key: 3,
    testName: "CPK (Creatine Phosphokinase) Test",
    testPrice: formatCurrency(750, "INR", "en-IN"),
  },
];

// Utility function to format currency
function formatCurrency(amount: number, currency: string, locale: string): string {
  try {
    return amount.toLocaleString(locale, {
      style: "currency",
      currency: currency,
      maximumFractionDigits: 0,
    });
  } catch (error) {
    console.error(
      `Error formatting currency for amount: ${amount}, currency: ${currency}, locale: ${locale}`,
      error
    );
    // Fallback to a basic formatting if toLocaleString fails
    return `${currency} ${amount}`;
  }
}


const LabProfile = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  return (
    <>
      <TopRow>
        <ProfileContainer>
          <ProfileHeader>Lab's Profile</ProfileHeader>
          <ProfileNameContainer>
            <img
              height="50px"
              src="https://play-lh.googleusercontent.com/ZvMvaLTdYMrD6U1B3wPKL6siMYG8nSTEnzhLiMsH7QHwQXs3ZzSZuYh3_PTxoU5nKqU"
            />{" "}
            <ProfileName>Ebisu Diagnostics Pvt. Ltd.</ProfileName>
          </ProfileNameContainer>
          <ContactContainer>
            <ContactRow>
              <ContactTextHighlighted>
                12, Mukti Apartment, Andher- (East), Mumbai.
              </ContactTextHighlighted>
            </ContactRow>
            <ContactRow>
              <img src={emailIcon} />
              <ContactText>ebisu-diagnostics@gmail.com</ContactText>
            </ContactRow>
            <ContactRow>
              <img src={phoneIcon} />
              <ContactText>+91 1234567890</ContactText>
            </ContactRow>
          </ContactContainer>
        </ProfileContainer>
        <HorizontalSep size={30} />
        <SupportContainer>
          <SupportHeader>Support Contact</SupportHeader>
          <SupportBody>
            <SupportRow>
              <SupportTextHighlighted>Mr. Ashok Trivedi</SupportTextHighlighted>
            </SupportRow>
            <SupportRow>
              <img src={emailIcon} />{" "}
              <SupportText>labsupport@hemehealth.io</SupportText>
            </SupportRow>
            <SupportRow>
              <img src={phoneIcon} />
              <SupportText>+91 1234567890</SupportText>
            </SupportRow>
          </SupportBody>
        </SupportContainer>
      </TopRow>
      <UserListContainer>
        <UserListText>Lab User</UserListText> <UserDropdown />
      </UserListContainer>
      <DetailsRow>
        <IncentivesSection>
          <HeaderRow>
            <Title>Incentives Earned</Title>
            <HeaderCount>
              {" "}
              {(115460).toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
                maximumFractionDigits: 0,
              })}{" "}
            </HeaderCount>
          </HeaderRow>
          <TableGrid>
            <GridRow>
              <GridCell>
                <div>Oct, 2021</div>
                <div>
                  {(25000).toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                    maximumFractionDigits: 0,
                  })}
                </div>
              </GridCell>
              <GridCell>
                <div>Oct, 2021</div>

                <div>250</div>
              </GridCell>
            </GridRow>
            <GridRow>
              <GridCell>
                <div>Oct, 2021</div>

                <div>250</div>
              </GridCell>
              <GridCell>
                <div>Oct, 2021</div>

                <div>250</div>
              </GridCell>
            </GridRow>
            <GridRow>
              <GridCell>
                <div>Oct, 2021</div>

                <div>250</div>
              </GridCell>
              <GridCell>
                <div>Oct, 2021</div>

                <div>250</div>
              </GridCell>
            </GridRow>
            <GridRow>
              <GridCell>
                <div>Oct, 2021</div>

                <div>250</div>
              </GridCell>
              <GridCell>
                <div>Oct, 2021</div>

                <div>250</div>
              </GridCell>
            </GridRow>
            <GridRow>
              <GridCell>
                <div>Oct, 2021</div>

                <div>250</div>
              </GridCell>
              <GridCell>
                <div>Oct, 2021</div>

                <div>250</div>
              </GridCell>
            </GridRow>
          </TableGrid>
        </IncentivesSection>
        <HorizontalLineSep margin={20} />
        <PatientsSection>
          <HeaderRow>
            <Title>Patients Onboarded</Title>
            <HeaderCount> {(115460).toLocaleString("en-IN")} </HeaderCount>
          </HeaderRow>
          <TableGrid>
            <GridRow>
              <GridCell>
                <div>Oct, 2021</div>
                <div>250</div>
              </GridCell>
              <GridCell>
                <div>Oct, 2021</div>

                <div>250</div>
              </GridCell>
            </GridRow>
            <GridRow>
              <GridCell>
                <div>Oct, 2021</div>

                <div>250</div>
              </GridCell>
              <GridCell>
                <div>Oct, 2021</div>

                <div>250</div>
              </GridCell>
            </GridRow>
            <GridRow>
              <GridCell>
                <div>Oct, 2021</div>

                <div>250</div>
              </GridCell>
              <GridCell>
                <div>Oct, 2021</div>

                <div>250</div>
              </GridCell>
            </GridRow>
            <GridRow>
              <GridCell>
                <div>Oct, 2021</div>

                <div>250</div>
              </GridCell>
              <GridCell>
                <div>Oct, 2021</div>

                <div>250</div>
              </GridCell>
            </GridRow>
            <GridRow>
              <GridCell>
                <div>Oct, 2021</div>

                <div>250</div>
              </GridCell>
              <GridCell>
                <div>Oct, 2021</div>

                <div>250</div>
              </GridCell>
            </GridRow>
          </TableGrid>
        </PatientsSection>
      </DetailsRow>
      <TestsRow>
        <HeaderRow>
          <Title>Health Tests</Title>
        </HeaderRow>
        <div>
          <CustomTable tableData={tableData} tableColumns={tableColumns} />
        </div>
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <Button
            variant="contained"
            sx={{ color: "#FFF" }}
            onClick={() => {
              if (!searchParams.has("addTest")) {
                searchParams.append("addTest", "true");
                setSearchParams(searchParams);
              }
            }}
          >
            Add Test
          </Button>
        </div>
      </TestsRow>
    </>
  );
};

export default LabProfile;
