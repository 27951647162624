import React, { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Alert, Box, CircularProgress, FormControl, MenuItem, Select, Snackbar, Typography } from "@mui/material";
import styled from "styled-components";
import { useCookies } from "react-cookie";
import axios from "axios";
import { API_URL } from "../utilities/constants";
import moment from "moment";

const Button = styled.button`
  background-color: #7aba56;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  min-width: 200px;
  height: 40px;
  border: none;
  cursor: pointer;
  padding: 0 10px;

  &:disabled {
    background-color: #ddebed;
    color: #fff;
    cursor: not-allowed;
  }
`;

export default function TextEditor({
  isReviewModal,
  scanData = {},
  setContent = {},
  isLoadingData=false,
  isShowSharing=false,
  onChangeSharing={},
  isAudioScribe=false,
  audioScribeText=''
}) {
  const template = `<table style="border-collapse: collapse; width: 100.368%; height: 146.594px; margin-left: 0px; margin-right: auto;" border="0"><colgroup> <col style="width: 16.8993%;"> <col style="width: 45.7752%;"> <col style="width: 37.3255%;"> </colgroup>
  <tbody>
  <tr style="height: 146.594px;">
  <td style="padding: 0px; line-height: 1.2; text-align: center; vertical-align: top;"><img src="https://d1csarkz8obe9u.cloudfront.net/posterpreviews/lab-logo-design-template-e606f0186c3e5d432a37ada0a4a5b843_screen.jpg?ts=1686940698" alt="" width="155" height="121"></td>
  <td style="padding: 0px; vertical-align: top; padding-top: 10px;">
  <h4 id="labName" style="margin: 0px; font-size: 20px; color: #0099cc; font-weight: bold; text-align: justify;">Ebisu Diagnostics &amp; Eye Care <strong>Center</strong></h4>
  <p style="color: #000000; font-size: 16px; margin: 0px 0px 10px; text-align: justify;"><span id="labAddress">Near Axis bank Pune - 461200&nbsp; &lt; </span>br&gt;Ph:7899987878&nbsp;<br>Timing : 09:00 AM 02:00 PM | Closed:Thursday <br><br></p>
  </td>
  <td style="padding: 0px; vertical-align: top; padding-top: 10px;">
  <h4 id="docName" style="margin: 0px; font-size: 20px; color: #0099cc; font-weight: bold; text-align: left;">Dr. omkar bhave</h4>
  <p style="color: #000; font-size: 16px; margin: 0 0 10px;"><span id="docDesignation">M.B.B.S, M.D, M.S</span> | Reg No. <span id="regNo">2134244</span> |&nbsp; <br>Mob No. 87899987878 <br><br><br></p>
  </td>
  </tr>
  </tbody>
  </table>
  <div style="background: #000; color: #fff; height: 2px; text-align: center; margin: 10px 0px; background-color: #0099cc;">&nbsp;</div>
  <table style="border-collapse: collapse; width: 100.221%; background-color: #eefcfe; border-style: hidden; border-color: #3598db; border-image: initial; height: 136px; margin-top: -8px;" border="0"><colgroup> <col style="width: 66.8626%;"> <col style="width: 33.1374%;"> </colgroup>
  <tbody>
  <tr style="height: 136px;">
  <td style="border-color: #3598db;">
  <p style="color: #000000; font-size: 16px; margin: 0px 0px 10px; line-height: 2;">Pateint Name - <strong> <span style="font-size: 12pt;"><span id="patientName">Test Patient</span></span> </strong> <br>Gender - <strong><span id="gender">12 dec 2024</span></strong> <br>DOB - <strong><span id="dob">12 dec 2024</span></strong></p>
  </td>
  <td style="border-color: #3598db;">
  <p style="color: #000000; font-size: 16px; margin: 0px 0px 10px; line-height: 2;">Date -&nbsp; <strong><span id="studyDate">13 fab 2024</span></strong> <br>Study ID - <strong><span id="studyId">1110.545.4543.5</span></strong> <br>Series Number - <strong><span id="seriesNo">1110.545.4543.5</span></strong></p>
  </td>
  </tr>
  </tbody>
  </table>
  <p style="line-height: 2;">Lab Name - <strong> <span id="labName" style="font-size: 12pt;">PUNE</span> </strong> <br>Modality - <strong><span id="modality">Female</span></strong> <br>Description - <strong><span id="description">Female</span></strong></p>
  <p style="line-height: 2;">&nbsp;</p>
  <hr>
  <p id="priscriptionBody" style="line-height: 2;">A non tetchkj sdf d gdg. d fg dfg d fg df gd fg. df g df gfd. g dsg z dsg. sd g a sd g a dfg. df g. d gda g dgjdjhgjdfgjhghjdf dfgdfkghdjfghjdfg dfgdfgdfhgdsfh dfhdfhfh sdfsdg dgd gds g dagdg ds gd g ads ga dfg adf non tetchkj sdf d gdg. d fg dfg d fg df gd fg. df g df gfd. g dsg z dsg. sd g a sd g a dfg. df g. d gda g dgjdjhgjdfgjhghjdf dfgdfkghdjfghjdfg dfgdfgdfhgdsfh dfhdfhfh sdfsdg dgd gds g dagdg ds gd g ads ga dfg adf non tetchkj sdf d gdg. d fg dfg d fg df gd fg. df g df gfd. g dsg z dsg. sd g a sd g a dfg. df g. d gda g dgjdjhgjdfgjhghjdf dfgdfkghdjfghjdfg dfgdfgdfhgdsfh dfhdfhfh sdfsdg dgd gds g dagdg ds gd g ads ga dfg adf non tetchkj sdf d gdg. d fg dfg d fg df gd fg. df g df gfd. g dsg z dsg. sd g a sd g a dfg. df g. d gda g dgjdjhgjdfgjhghjdf dfgdfkghdjfghjdfg dfgdfgdfhgdsfh dfhdfhfh sdfsdg dgd gds g dagdg ds gd g ads ga dfg adf non tetchkj sdf d gdg. d fg dfg d fg df gd fg. df g df gfd. g dsg z dsg. sd g a sd g a dfg. df g. d gda g dgjdjhgjdfgjhghjdf dfgdfkghdjfghjdfg dfgdfgdfhgdsfh dfhdfhfh sdfsdg dgd gds g dagdg ds gd g ads ga dfg adf non tetchkj sdf d gdg. d fg dfg d fg df gd fg. df g df gfd. g dsg z dsg. sd g a sd g a dfg. df g. d gda g dgjdjhgjdfgjhghjdf dfgdfkghdjfghjdfg dfgdfgdfhgdsfh dfhdfhfh sdfsdg dgd gds g dagdg ds gd g ads ga dfg adf non tetchkj sdf d gdg. d fg dfg d fg df gd fg. df g df gfd. g dsg z dsg. sd g a sd g a dfg. df g. d gda g dgjdjhgjdfgjhghjdf dfgdfkghdjfghjdfg dfgdfgdfhgdsfh dfhdfhfh sdfsdg dgd gds g dagdg ds gd g ads ga dfg adf&nbsp;</p>
  <table style="border-collapse: collapse; width: 101.988%; height: 89px;" border="0"><colgroup> <col style="width: 100%;"> </colgroup>
  <tbody>
  <tr style="height: 89px;">
  <td>&nbsp;</td>
  </tr>
  </tbody>
  </table>
  <p style="color: #000000; font-size: 16px; margin: 0px 0px 10px;">&nbsp;</p>
  <table style="border-collapse: collapse; width: 100%;" border="0"><colgroup> <col style="width: 77.0879%;"> <col style="width: 22.9484%;"> </colgroup>
  <tbody>
  <tr>
  <td>&nbsp;</td>
  <td>
  <p style="margin: 0px; font-size: 20px; color: #0099cc; font-weight: bold; text-align: left;"><span style="font-size: 12pt; font-family: 'comic sans ms', sans-serif; color: #7e8c8d;">signature</span></p>
  <h4 id="docName" style="margin: 0px; font-size: 20px; color: #0099cc; font-weight: bold; text-align: left;">Dr. omkar bhave</h4>
  <p style="color: #000; font-size: 16px; margin: 0 0 10px;"><span id="docDesignation">M.B.B.S, M.D, M.S</span> | Reg No. <span id="regNo">2134244</span> |&nbsp; <br>Mob No. 87899987878</p>
  </td>
  </tr>
  </tbody>
  </table>`;

  const [cookies] = useCookies();
  const [templateContent, setTemplateContent] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isEditorLoading, setEditorLoading] = useState(false);
  const [templateId, setTemplateId] = useState('');
  const [sharingStatus, setSharingStatus] = useState("AVAILABLE");


  


  const editorRef = useRef(null);


  useEffect(() => {
    fetchTemplate();
  }, []);
const DropdownIconButton = (props) => {
  const { className } = props;
  return (
    <Box className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="5" fill="#CC8552" />
        <path
          d="M11.9832 13.2768L8.20119 9.49285C8.06543 9.36695 7.88612 9.29862 7.70101 9.30224C7.51589 9.30587 7.3394 9.38116 7.20867 9.51227C7.07794 9.64339 7.00317 9.82011 7.0001 10.0052C6.99702 10.1904 7.06588 10.3695 7.19219 10.5048L11.4762 14.7918C11.6061 14.9214 11.781 14.9959 11.9645 14.9998C12.1479 15.0037 12.3258 14.9368 12.4612 14.8128L16.7752 10.5128C16.8417 10.4465 16.8945 10.3678 16.9306 10.281C16.9666 10.1943 16.9853 10.1013 16.9854 10.0074C16.9856 9.91348 16.9672 9.82045 16.9314 9.73362C16.8956 9.64679 16.843 9.56786 16.7767 9.50135C16.7104 9.43483 16.6316 9.38203 16.5449 9.34596C16.4582 9.30989 16.3652 9.29125 16.2712 9.29111C16.1773 9.29097 16.0843 9.30933 15.9975 9.34515C15.9106 9.38096 15.8317 9.43353 15.7652 9.49985L11.9832 13.2768Z"
          fill="white"
        />
      </svg>
    </Box>
  );
};

const DropdownAvailableIconButton = (props) => {
  const { className } = props;
  return (
    <Box className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="5" fill="#7ABA56" />
        <path
          d="M11.9832 13.2768L8.20119 9.49285C8.06543 9.36695 7.88612 9.29862 7.70101 9.30224C7.51589 9.30587 7.3394 9.38116 7.20867 9.51227C7.07794 9.64339 7.00317 9.82011 7.0001 10.0052C6.99702 10.1904 7.06588 10.3695 7.19219 10.5048L11.4762 14.7918C11.6061 14.9214 11.781 14.9959 11.9645 14.9998C12.1479 15.0037 12.3258 14.9368 12.4612 14.8128L16.7752 10.5128C16.8417 10.4465 16.8945 10.3678 16.9306 10.281C16.9666 10.1943 16.9853 10.1013 16.9854 10.0074C16.9856 9.91348 16.9672 9.82045 16.9314 9.73362C16.8956 9.64679 16.843 9.56786 16.7767 9.50135C16.7104 9.43483 16.6316 9.38203 16.5449 9.34596C16.4582 9.30989 16.3652 9.29125 16.2712 9.29111C16.1773 9.29097 16.0843 9.30933 15.9975 9.34515C15.9106 9.38096 15.8317 9.43353 15.7652 9.49985L11.9832 13.2768Z"
          fill="white"
        />
      </svg>
    </Box>
  );
};
  const onSaveTemplate = () => {
    if (editorRef.current) {
      
      let data = editorRef.current.getContent();
      setTemplateContent(data);
      saveTemplate(data);
    }
  };

  const onGeneratePdf = () => {
    if (!editorRef.current) {
      console.error("Editor is not initialized yet.");
      return;
    }
    if (setContent) {
      let data = editorRef.current.getContent();
      setTemplateContent(data);
      setContent(data,sharingStatus);
    }
  };

  const saveTemplate = (content) => {
    setLoading(true);
    const token = cookies["access_token"];
    const endpointUrl = `${API_URL}/template`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const payload = {
      id: templateId,
      template_content: content,
    };
    axios
      .patch(endpointUrl, payload, config)
      .then((response) => {
        if (response.data && response.data?.status == "success") {
          setLoading(false);
          setEditorLoading(true)
        } else {
          alert("Something went wrong!!");
          setEditorLoading(false)

        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setEditorLoading(false)

      });
  };

  const fetchTemplate = () => {
    const token = cookies["access_token"];
    const endpointUrl = `${API_URL}/template`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(endpointUrl, config)
      .then((response) => {
        if (response.data && response.data?.status == "success") {
          if (Object.keys(scanData).length) {
            let temp = response?.data?.result[0]?.html_content;
            let toBeUpdated = document.createElement("div");
            toBeUpdated.innerHTML = `${temp}`;
            console.log("scanData",scanData);
            //  let labAddress = toBeUpdated.querySelector("#labAddress")
            //  labAddress.innerHTML = templateData.data.performer_details?.contact_info?.address
            //  let labPhoneNo = toBeUpdated.querySelector("#labPhoneNo")
            //  labPhoneNo.innerHTML = templateData.data.performer_details?.contact_info?.phone

            let patientName = toBeUpdated.querySelector("#patientName");
            patientName.innerHTML = scanData?.patient_name || "-"

            let patient_gender = toBeUpdated.querySelector("#gender");
            patient_gender.innerHTML = scanData?.patient_gender || "-"

            let referredBy = toBeUpdated.querySelector("#referredBy");
            referredBy.innerHTML = scanData?.referredBy || "-";

            let studyDate = toBeUpdated.querySelector("#studyDate");
            studyDate.innerHTML = formattedDate(scanData?.study_datetime) || formattedDate(scanData?.study_timestamp) ||"-"

            let age = toBeUpdated.querySelector("#age");
            age.innerHTML = scanData?.patient_age || "-"
            age.style.wordBreak = 'break-all';

            let modality = toBeUpdated.querySelector("#modality");
            modality.innerHTML = scanData?.modality_code || scanData?.modality_list || "-";

            let description = toBeUpdated.querySelector("#description");
            description.innerHTML = scanData?.description || scanData?.study_description|| "-"
            description.style.wordBreak = 'break-all';


            //  let modality = toBeUpdated.querySelector("#modality")
            //  modality.innerHTML = templateData?.data?.study_info?.series[0]?.modality || ''

            //  let modality = toBeUpdated.querySelector("#modality")
            //  modality.innerHTML = templateData.data.study_info.modality


            if(isAudioScribe  && audioScribeText){
        let toBeUpdatedData = toBeUpdated.outerHTML
              let parser = new DOMParser();
          let doc = parser.parseFromString(toBeUpdatedData, 'text/html');
        
          let priscriptionBodies = doc.querySelectorAll('#priscriptionBody');
        
          // Check if there are multiple div#priscriptionBody elements
          if (priscriptionBodies.length > 1) {
            // Remove all div#priscriptionBody elements except the first one
            for (let i = 1; i < priscriptionBodies.length; i++) {
              priscriptionBodies[i].remove();
            }
          }
          // If there's at least one div#priscriptionBody, update the content of the first one
          if (priscriptionBodies.length > 0) {
            priscriptionBodies[0].innerHTML = audioScribeText; 
          }
                setTemplateContent(doc.body.innerHTML);
            }else{
              setTemplateContent(toBeUpdated.outerHTML || temp);

            }
          } else {
            setTemplateContent(response?.data?.result[0]?.html_content || "");
            setTemplateId(response?.data?.result[0]?.id)
          }
        } else {
          alert("Something went wrong!!");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  var imageFilePicker = function (callback, value, meta) {               
     editorRef.activeEditor.windowManager.open({
        title: 'Image Picker',
        url: '/images/getimages',
        width: 650,
        height: 550,
        buttons: [{
            text: 'Insert',
            onclick: function () {
                //.. do some work
                editorRef.activeEditor.windowManager.close();
            }
        }, {
            text: 'Close',
            onclick: 'close'
        }],
    }, {
        oninsert: function (url) {
            callback(url);
            console.log("derp");
        },
    });
};


function toDataUrl(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
            callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}

const formattedDate = (dateString) => {
  if (!dateString) {
    return "";
  }
  return moment(dateString).format("DD-MMM-YYYY hh:mm a");
};
  return (
    <>
<Snackbar
          open={isEditorLoading}
          autoHideDuration={3000}
          onClose={() => {
            setEditorLoading(false);
          }}
        >
          <Alert
            onClose={() => {
                setEditorLoading(false);
            }}
            severity={"success"}
            sx={{ width: "100%"}}
          >
            {
              "Template Saved Successfully"
            }
          </Alert>
        </Snackbar>

<div style={{minHeight:'80vh'}}>
<Editor
        apiKey="1lcuxds8d88qwvx54ut612dlywerd4kzoglczqjriehie6bb"
        onInit={(_evt, editor) => (editorRef.current = editor)}
        init={{
          editable_root: true,
          height: "80vh",
          plugins:
            "preview anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount  ",
          toolbar:
            "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | preview | spellcheckdialog a11ycheck  | align lineheight |  numlist bullist indent outdent | emoticons charmap | removeformat", //link image media table
          
            /* enable automatic uploads of images represented by blob or data URIs*/
            automatic_uploads: true,
            file_picker_types: 'image file',
            /* and here's our custom image picker*/
            file_picker_callback: (cb, value, meta) => {
            
              if (meta.filetype == 'image') {
                const input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.setAttribute('accept', 'image/*');
                input.onchange = function () {
                    var file = input.files[0];
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        cb(e.target.result, {
                            alt: file.name
                        });
                    };
                    reader.readAsDataURL(file);
                };
                input.click();

            }
            
            },
           images_replace_blob_uris:false,
           
        }}
        initialValue={templateContent}
        style={{ height: "100%" }}
        onNodeChange={(e) => {
            if (e && e.element.nodeName.toLowerCase() == 'img') {
                toDataUrl(e.element.getAttribute('src'), function(myBase64) {
                    editorRef.current.dom.setAttribs(e.element, {
                        src: myBase64,
                      })
                });

              
            }
          }}
      />
</div>
      
{isShowSharing ?<Box
                      sx={{
                        display: "flex",
                        whiteSpace: "nowrap",
                        alignItems: "center",
                        gap: "10px",
                        paddingTop:'16px',
                        paddingBottom:'16px'
                      }}
                    >
                      Sharing Status
                      <FormControl
                        
                        sx={{minWidth:'130px' ,height: "36px" }}
                        size="small"
                      >
                        <Select
                        
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          IconComponent={sharingStatus == "AVAILABLE" ? DropdownAvailableIconButton : DropdownIconButton}
                          onChange={(event) =>{
                          setSharingStatus(event.target.value)
                          }
                          }
                          sx={{
                            width:'100%',
                            maxWidth:'14opx',
                      
                            '.MuiOutlinedInput-root': {
                              borderColor: sharingStatus == "AVAILABLE" ? "#7ABA56" : "#CC8552",
                            },
                            '.MuiSelect-select': {
                              color: sharingStatus == "AVAILABLE" ? "#7ABA56" : "#CC8552",
                            },
                            ".MuiSelect-icon": {
                              top: 'calc(50% - 12px)',
                            },
                            ".MuiSelect-icon.MuiSelect-iconOpen": {
                              top: 'calc(50% - 17px)',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: sharingStatus == "AVAILABLE" ? "#7ABA56" : "#CC8552",
                              outline: "none", // Replace 'your-color' with the desired color
                            },
                            'hover': {
                              outline: 'none',
                              border: sharingStatus == "AVAILABLE" ? "#7ABA56" : "#CC8552"
                            },
                            "fieldset": {
                              borderColor: sharingStatus == "AVAILABLE" ? "#7ABA56" : "#CC8552",
                            },
                          }}
                          value={sharingStatus}
                        >
                          <MenuItem value={"RESTRICTED"}>Restricted</MenuItem>
                          <MenuItem value={"AVAILABLE"}>Available</MenuItem>
                        </Select>
                        
                      </FormControl>
                      <Typography sx={{fontSize:'14px',fontWeight:'400',color:'#808080'}}>
                        { sharingStatus == 'RESTRICTED' ?"This file will be only be available for doctors and labs" : "This file will be available for patient and doctor to view"}
                        </Typography>
                    </Box>:null}
  
      <div style={{ textAlign: "left", marginTop: "20px" }}>
        
        <Button
          disabled={isLoading || isLoadingData}
          onClick={() => (isReviewModal ? onGeneratePdf() : onSaveTemplate())}
        >
          {isLoading || isLoadingData ? <CircularProgress size={26} color="inherit" /> : isReviewModal ?"Attach":"Save"}
        </Button>
      </div>
    </>
  );
}
